// 404

.not-found {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 100vh;
    padding: 0 15px;

    .btn {
        background-color: #fefefe;
        display: inline-table;
        float: none;

        &:before {
            background-color: #1B1B1B;
            z-index: 0;
        }
    }

    .not-found__left {
        flex: 0 0 50%;
    }

    .not-found__right {
        flex: 0 0 50%;
        line-height: 1.5;
        padding-left: 100px;

        @media screen and (max-width: 1024px) {
            padding-left: 60px;
        }

        @media screen and (max-width: 767px) {
            padding-left: 20px;
        }

        .text-error-title {
            font-size: 144px;
            margin-bottom: 5px;
            color: #fff;

            @media screen and (max-width: 1024px) {
                font-size: 80px;
            }

            @media screen and (max-width: 767px) {
                font-size: 30px;
            }
        }

        .text-error-subtitle {
            font-size: 36px;
            color: #fdfdfe;
            margin-bottom: 25px;

            @media screen and (max-width: 767px) {
                font-size: 18px;
                margin-bottom: 5px;
            }
        }

        .text-error-message {
            font-size: 20px;
            color: #fdfdfe;
            margin-bottom: 25px;

            @media screen and (max-width: 1024px) {
                font-size: 18px;
            }

            @media screen and (max-width: 767px) {
                font-size: 12px;
                line-height: 1.35;
                margin-bottom: 15px;
            }
        }
    }

    .not-found-btn {
        font-size: 18px;
        text-transform: uppercase;
        letter-spacing: .05em;
        line-height: 1.5;

        @media screen and (max-width: 767px) {
            font-size: 12px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .not-found {
        padding: 50px 15px;

        .not-found__left,
        .not-found__right {
            flex: 0 0 100%;
        }

        .not-found__left {
            order: 2;
        }

        .not-found__right {
            order: 1;

            .text-error-title {
                text-align: center;
                font-size: 65px;
                margin-bottom: 15px;
            }

            .text-error-subtitle {
                text-align: center;
                padding-bottom: 0;
            }

            .text-error-message {
                max-width: 80%;
                margin: 0 auto 15px;
                text-align: center;
                line-height: 1.5;
            }
        }

    }
}
