@import "pages/not-found";

.screen-reader-text {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
}

.d-flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.hero-scroll-link {
    background-color: rgba(0, 0, 0, 0.6);
    border-color: #000;
    font-size: 20px;

    &--pulse {
        cursor: pointer;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.4);
        animation: pulse 2s infinite;

        &:hover {
            animation: none;
        }
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.6);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

header,
.visshare {
    width: 100%;
}

.visshare {
    right: 0;
}

.interest-facts-subtitle {
    bottom: 25%;
}

.bg.skrollable.skrollable-between:before {
    content: "";
    background: rgba(0, 0, 0, 0.25);
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.hero-wrap-item h3 {
    letter-spacing: 2px;
    max-width: 70%;
    margin: 0 auto;
    line-height: 1.5;
    font-weight: 300;

    @media screen and (max-width: 991px) {
        display: none;
    }
}

.footer-logo img {
    max-width: 150px;
}

.logo-holder {
    top: 32px;
}

p {
    font-size: 14px;
}

.w-100 {
    width: 100%;
}

.full-visible {
    opacity: 1 !important;
}

.overflow-hidden {
    overflow: hidden;
}

#contact-form .form-label {
    float: left;
    font-size: 10px;
    font-family: "Muli", sans-serif;
    line-height: 12px;
    text-transform: uppercase;
    position: relative;
    font-weight: 800;
    letter-spacing: 2px;
    color: #292929;
    width: 100%;
    text-align: left;

    &.required {

        .form-label__text:after {
            content: '*';
            color: #db0e24;
            padding: 0 3px;
        }
    }

    .form-label__error {
        color: #db0e24;
        font-size: 10px;
        letter-spacing: 0;
        display: block;
        padding: 5px 0;
        text-transform: none;
        font-weight: normal;
    }

    &.error {
        input,
        textarea {
            border-bottom: 1px solid #db0e24;
        }
    }
}

.ser-list li {
    font-size: 12px;
    font-weight: 400;

    strong {
        font-weight: bold;
    }
}

#contact-form textarea {
    padding-bottom: 15px;
    margin: 15px 0 35px;
}


.submit-btn {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background: #000;
        -webkit-transition: all 200ms linear;
        -moz-transition: all 200ms linear;
        -o-transition: all 200ms linear;
        -ms-transition: all 200ms linear;
        transition: all 200ms linear;
        z-index: -1;
        -webkit-transition-delay: 200ms; /* Safari */
        transition-delay: 200ms;
    }

    &:hover,
    &:disabled {
        color: #fff !important;

        &:before {
            width: 100%;
        }
    }
}

.default-theme {
    .toast-success {
        background-color: #2f6844 !important;
    }

    .toast-error {
        background-color: #db0e24 !important;
    }
}

.left-align-small {
    left: 7%;
}

.right-align-small {
    right: 7%;
}

.technologies-list {
    .technologies-item {
        padding: 40px 30px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        transition: all 0.3s;

        .special-line {
            position: absolute;
            background-color: rgba(255, 255, 255, 0.4);

            @media only screen and (max-width: 991px) {
                display: none;
            }

            &--left-border {
                width: 1px;
                height: 90%;
                left: 0;
                top: 5%;
            }

            &--right-border {
                width: 1px;
                height: 90%;
                right: 0;
                top: 5%;
            }

            &--top-border {
                height: 1px;
                width: 90%;
                left: 5%;
                top: 0;
            }

            &--bottom-border {
                height: 1px;
                width: 90%;
                left: 5%;
                bottom: 0;
            }
        }

        img {
            max-width: 100%;
            opacity: 0.7;
            transition: all 0.3s;
            transform: scale(1);
        }
    }

    .technologies-item:hover {
        background-color: rgba(0, 0, 0, 0.5);

        img {
            opacity: 1;
            transform: scale(1.1);
        }
    }
}

.section-title.section-title--technologies span:before {
    background: #fff;
}

@media screen and (max-width: 1236px) {
    .custom-link-holder {
        margin-top: 0 !important;
    }
}

@media screen and (max-width: 991px) {
    .custom-link-holder {
        margin-top: 0 !important;
    }

    .about-us-title {
        margin-top: 50px;
    }

    .about-us-section .single-image-block {
        display: none;
    }
}

@media only screen and (max-width: 640px) {
    .serv-item {
        padding: 10px 0;
    }
}

.how-we-make-work-content {
    overflow-x: scroll;
}

.how-we-make-work-processes__list {
    min-width: 1142px;
    display: grid;
    grid-template-columns: 200px 200px 210px 285px 200px;
    grid-template-rows: 1fr;
    margin-top: 20px;
}

.how-we-make-work-processes__item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 15px;
    transition: .3s all ease-in-out;

    &:hover {
        .how-we-make-work-processes__item-img {
            transform: scale(1.1);
        }
    }

    &-img {
        margin-bottom: 15px;
        transition: .3s all ease-in-out;
    }

    &-title {
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    &-description {
        font-size: 11px;
        line-height: 1.35;
        font-weight: 300;
        letter-spacing: 1px;
    }
}

@media only screen and (max-width: 1400px) {
    .how-we-make-work-timeline {
        padding: 0 20px 0 40px;
    }
}

.about-us-contact-btn {
    margin-top: 30px;
}

.about-us-list {
    text-align: left;
    list-style: none;
    font-size: 14px;
    line-height: 1.5;

    li {
        padding-bottom: 10px;
        position: relative;

        span {
            padding-left: 30px;
            display: block;

            &:before {
                content: '';
                position: absolute;
                top: 35%;
                top: calc(50% - 5px);;
                left: 0;
                width: 20px;
                height: 1px;
                background: #000;
                -webkit-transition: all 200ms linear;
                -moz-transition: all 200ms linear;
                -o-transition: all 200ms linear;
                -ms-transition: all 200ms linear;
                transition: all 200ms linear;
            }

            &:hover:before {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }
}

.our-experience-list {
    text-align: left;
    list-style: none;
    font-size: 14px;
    line-height: 1.5;

    li {
        padding-bottom: 10px;
        position: relative;

        span {
            padding-left: 20px;
            display: block;

            &:before {
                content: '';
                position: absolute;
                top: 25%;
                top: calc(50% - 8px);;
                left: 0;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #fff;
                -webkit-transition: all 200ms linear;
                -moz-transition: all 200ms linear;
                -o-transition: all 200ms linear;
                -ms-transition: all 200ms linear;
                transition: all 200ms linear;
            }
        }
    }
}

@media only screen and (max-width: 640px) {
    .hero-wrap-item h2 {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 1036px) {
    nav li a.scroll-link {
        font-size: 14px;
    }

    .first-section {
        min-height: 550px;
    }
}
